<template>
  <div class="ArticleDetails">
    <div class="ArticleDetails_h">
      <h4 class="title">{{ essayTitle }}</h4>
      <div class="address_date">
        <span class="address">中智薪税</span>
        <span>{{ updateTime }}</span>
      </div>
      <div class="img_list" @click="tadvertsClick" v-if="tadverts.advertImage">
        <van-image
          width="100%"
          height="150"
          fit="cover"
          :src="tadverts.advertImage"
        />
      </div>
      <div class="content">
        <div class="content_text" v-html="essayContext"></div>
        <!-- <p class="source">来源：北京如日报</p> -->
        <!-- <div class="content_text" v-html="essayContext">
        企业认为<span class="highlight">“生育假成本全由企业买单不显示”</span
        >，很多女职工则担心就业歧视因此加重。相关专家表示，建议对招聘女职工多的企业给予政策优惠，以消除企业和个人顾虑。
      </div> -->
      </div>
    </div>

    <copyrightIp />
  </div>
</template>
<script>
import { findTEssayById } from "@/api/policyLibrary";
import { Image as VanImage } from "vant";
import { activityCheck } from "@/api/index";
import getJSSDK from "@/utils/wxShare";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [VanImage.name]: VanImage,
    copyrightIp,
  },
  data() {
    return {
      essayTitle: "",
      updateTime: "",
      essayContext: "",
      tadverts: {
        advertImage: "",
        advertUrl: "",
      },
    };
  },
  mounted() {
    this.getFindTEssayById();
    // addEventListener("scroll", this.handleScroll); //监听函数
  },
  methods: {
    // 广告跳转
    tadvertsClick() {
      window.location.href = this.tadverts.advertUrl;
    },
    getFindTEssayById() {
      let tessayId = this.$route.query.tessayId;
      let openId = localStorage.getItem("code");
      let type = "2";
      findTEssayById({ tessayId, openId, type }).then((res) => {
        const data = res.data.data;
        this.essayTitle = data.tessay.essayTitle;
        this.updateTime = data.tessay.updateTime?.split("T")[0];
        this.essayContext = data.tessay.essayContext;
        if (data.tadverts) {
          this.tadverts.advertImage = data.tadverts.advertImage || "";
          this.tadverts.advertUrl = data.tadverts.advertUrl || "";
        }
        getJSSDK({
          title: data.tessay.essayTitle || "中智薪税",
          desc: "智享薪税 智荟人才", // 分享描述
          link: this.$route.fullPath,
        });
      });
    },
    handleScroll() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        // let openId = localStorage.getItem("code");
        // let activityId = "2";
        // activityCheck({ openId, activityId }).then((res) => {
        //   console.log(res.data, "asdasdasdasd");
        // });
        // console.log("到底了");
        //你想做的事情
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ArticleDetails {
  width: 100%;
  overflow: hidden;
  background: #fff;
  .ArticleDetails_h {
    min-height: 100vh;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
  }
  .address_date {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    .address {
      color: #2e9df3;
      padding-right: 20px;
    }
  }
  .img_list {
    width: 100%;
    text-align: center;
  }
  .content {
    width: 100%;
    padding: 10px;
    .source {
      color: #b4b0b0;
      padding: 20px 0;
    }
    .content_text {
      line-height: 25px;
      ::v-deep div {
        height: auto !important;
      }
      .highlight {
        color: #1b82d2;
        font-weight: bold;
      }
    }
  }
}
</style>
